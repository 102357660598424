// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA5_xElq6ph57L2IPRj6-oHFCJ3vqSQ9EI",
    authDomain: "ubaltdex.firebaseapp.com",
    databaseURL: "https://ubaltdex-default-rtdb.firebaseio.com",
    projectId: "ubaltdex",
    storageBucket: "ubaltdex.appspot.com",
    messagingSenderId: "434762487169",
    appId: "1:434762487169:web:f4f03d22e582e082d7c480",
    measurementId: ''
  },
  onesignal: {
    appId: 'f7cb0ab5-6e5f-4e8a-a7d8-b7c8f69f9b2d',
    googleProjectNumber: '434762487169',
    restKey: 'ODM2YWQ5MDAtNjJjMS00M2M3LTg4YmQtMzU0ZWZkOWIyMjk1'
  },
  stripe: {
    sk: 'pk_test_51HXvUmBQVizhpP0wVXnqvIsMGEbk2qhpeHEnKKeo2TDx2SCDjaTRyrLXsBqqb3YdQuLfqlmido3GBgD1D8EdwWzA009SNhXx17'
  },
  general: {
    symbol: '$',
    code: 'MX'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
